import React from 'react';
import cntl from 'cntl';
import { Testimonial as ContentfulTestimonial } from '../../../@types';

interface TestimonialProps {
  testimonial: ContentfulTestimonial;
}

const containerClassName = cntl`
  mt-8
  border-t
  border-gray-200
  pt-6
`;

const descriptionClassName = cntl`
  text-base
  text-gray-500
`;

const authorClassName = cntl`
  text-base
  font-medium
  text-gray-700
`;

const Testimonial: React.FC<TestimonialProps> = ({ testimonial }) => {
  return (
    <div className={containerClassName}>
      <blockquote>
        <div>
          <p className={descriptionClassName}>
            {testimonial.description.description}
          </p>
        </div>
        <footer className="mt-3">
          <div className={authorClassName}>
            {testimonial.author}, {testimonial.carModel}
          </div>
        </footer>
      </blockquote>
    </div>
  );
};

export default Testimonial;
