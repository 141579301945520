import React from 'react';
import cntl from 'cntl';

interface CardProps {
  name: string;
  value: string;
}

const wrapperClassName = cntl`
  flex
  flex-col
  border-t
  border-b
  border-gray-100
  p-6
  text-center
  sm:border-0
  sm:border-l
  sm:border-r
`;

const valueClassName = cntl`
  order-1
  text-5xl
  font-extrabold
  text-primary-600
`;

const nameClassName = cntl`
  order-2
  mt-2
  text-lg
  leading-6
  font-medium
  text-gray-500
`;

const Card: React.FC<CardProps> = ({ name, value }) => {
  return (
    <div className={wrapperClassName}>
      <dt className={nameClassName}>{name}</dt>
      <dd className={valueClassName}>{value}</dd>
    </div>
  );
};

export default Card;
