import React from 'react';
import cntl from 'cntl';

const containerClassName = cntl`
  hidden
  sm:block
`;

const absoluteCutClassName = cntl`
  absolute
  inset-y-0
  left-0
  w-1/2
  bg-gray-50
  rounded-r-3xl
`;

const svgClassName = cntl`
  absolute
  top-8
  left-1/2
  -ml-3
`;

const GrayRects: React.FC = () => {
  return (
    <div aria-hidden="true" className={containerClassName}>
      <div className={absoluteCutClassName} />
      <svg
        className={svgClassName}
        width={404}
        height={392}
        fill="none"
        viewBox="0 0 404 392"
      >
        <defs>
          <pattern
            id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
            x={0}
            y={0}
            width={20}
            height={20}
            patternUnits="userSpaceOnUse"
          >
            <rect
              x={0}
              y={0}
              width={4}
              height={4}
              className="text-gray-200"
              fill="currentColor"
            />
          </pattern>
        </defs>
        <rect
          width={404}
          height={392}
          fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)"
        />
      </svg>
    </div>
  );
};

export default GrayRects;
