import React from 'react';
import cntl from 'cntl';

const descriptionClassName = cntl`
  text-base
  text-gray-800
  mt-3
  mb-8
  sm:mt-5
  sm:mb-12
  sm:text-lg
  lg:text-base
  xl:text-lg
`;

const Description: React.FC = ({ children }) => {
  return <p className={descriptionClassName}>{children}</p>;
};

export default Description;
