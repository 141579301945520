import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { gql } from 'graphql-request';
import { IndexPageQuery, IndexPageRuntimeQuery } from '../@types';
import SEO from '../components/seo';
import MainSection from '../components/landing/sections/main';
import StatsSection from '../components/landing/sections/stats';
import TestimonialsSection from '../components/landing/sections/testimonials';
import FeaturesSection from '../components/landing/sections/features';
import FounderMessage from '../components/landing/sections/founder-message';
import PricingSection from '../components/landing/sections/pricing';
import CTASection from '../components/landing/sections/cta';
import FooterSection from '../components/landing/sections/footer';
import useContentful from '../runtime/hooks/useContentful';

const Index: React.FC<PageProps<IndexPageQuery>> = ({ data }) => {
  const runtimeData = useContentful<IndexPageRuntimeQuery>(runtimeQuery);

  // Meta title and description
  const title = 'Mobile Car Detailing in Roseville, CA';
  const description =
    "Dave's Auto Details is a mobile car detailing provider serving all of Roseville. Rocklin, Loomis, Granite Bay, Penryn, New Castle, Auburn, Lincoln, Whitney, Citrus Heights, Folsom, Orangevale, Fair Oaks, Antelope, and Eldorado Hills, California.";

  return (
    <>
      <SEO title={title} description={description} useTitleTemplate={false} />
      <MainSection data={data} runtimeData={runtimeData} />
      <StatsSection data={data} />
      <TestimonialsSection data={data} />
      <FeaturesSection data={data} />
      <FounderMessage />
      <PricingSection data={data} runtimeData={runtimeData} />
      <CTASection data={data} runtimeData={runtimeData} />
      <FooterSection serviceAreas={data.serviceAreas} />
    </>
  );
};

export default Index;

const runtimeQuery = gql`
  query IndexPageRuntimeQuery {
    serviceAreas: serviceAreaCollection(limit: 180) {
      items {
        sys {
          id
        }
        state
        county
        city
      }
    }
    carTypes: carTypeCollection(limit: 20, order: order_ASC) {
      items {
        sys {
          id
        }
        subTypes
        name
        packages: packagesCollection(limit: 20) {
          items {
            sys {
              id
            }
            name
            price
            time
            calloutText
            serviceType
            services: servicesCollection(limit: 20) {
              items {
                sys {
                  id
                }
                name
                description
              }
            }
            categories: serviceCategoriesCollection(limit: 3) {
              items {
                sys {
                  id
                }
              }
            }
          }
        }
        servicePricing: servicePricingCollection(limit: 20) {
          items {
            sys {
              id
            }
            price
            time
            calloutText
            service {
              sys {
                id
              }
              name
              description
              serviceCategory {
                sys {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const query = graphql`
  query IndexPageQuery {
    siteTitle: contentfulSiteField(alias: { eq: "site-title" }) {
      description {
        description
      }
    }
    siteDescription: contentfulSiteField(alias: { eq: "site-description" }) {
      description {
        description
      }
    }
    businessPhone: contentfulSiteField(alias: { eq: "business-phone" }) {
      description {
        description
      }
    }
    siteAboutText: contentfulSiteField(alias: { eq: "site-about-text" }) {
      description {
        description
      }
    }
    servicesSummary: contentfulSiteField(alias: { eq: "services-summary" }) {
      description {
        description
      }
    }
    businessDescription: contentfulSiteField(
      alias: { eq: "business-description" }
    ) {
      description {
        description
      }
    }
    testimonials: allContentfulTestimonial(filter: { enabled: { eq: true } }) {
      nodes {
        contentful_id
        author
        carModel
        description {
          description
        }
      }
    }
    serviceAreas: allContentfulServiceArea {
      nodes {
        contentful_id
        city
        county
        state
      }
    }
  }
`;
