import React from 'react';
import cntl from 'cntl';
import { LocationMarkerIcon } from '@heroicons/react/outline';
import { StaticImage } from 'gatsby-plugin-image';
import TextContent from './text-content';

const containerClassName = cntl`
  px-4
  max-w-xl
  mx-auto
  sm:px-6
  lg:py-32
  lg:max-w-none
  lg:mx-0
  lg:px-0
  lg:col-start-2
`;

const imageContainerClassName = cntl`
  mt-12
  sm:mt-16
  lg:mt-0
  lg:col-start-1
`;

const imageWrapperClassName = cntl`
  pr-4
  -ml-48
  sm:pr-6
  md:-ml-16
  lg:px-0
  lg:m-0
  lg:relative
  lg:h-full
`;

const imageClassName = cntl`
  w-full
  rounded-xl
  shadow-xl
  ring-1
  ring-black
  ring-opacity-5
  lg:absolute
  lg:right-0
  lg:h-full
  lg:w-auto
  lg:max-w-none
`;

const ServiceAreas: React.FC = () => {
  return (
    <>
      <div className={containerClassName}>
        <TextContent
          icon={LocationMarkerIcon}
          title="We come directly to you"
          ctaPath="/mobile-detailing-service-areas"
          ctaText="Full service area list"
        >
          We have been serving the greater Roseville, CA area with professional
          mobile detailing and ceramic coating since 2014. With a perfect 5-star
          rating on Google, you can expect an exceptional customer experience
          from a trusted member of the Roseville community.
        </TextContent>
      </div>
      <div className={imageContainerClassName}>
        <div className={imageWrapperClassName}>
          <StaticImage
            placeholder="blurred"
            className={imageClassName}
            src="../../../assets/service-areas.png"
            alt="Service areas"
            aspectRatio={1}
            layout="fullWidth"
          />
        </div>
      </div>
    </>
  );
};

export default ServiceAreas;
