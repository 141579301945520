import React from 'react';
import cntl from 'cntl';
import {
  IndexPageQuery,
  IndexPageRuntimeQuery,
  PageSectionProps,
} from '../../../@types';
import GrayRects from '../cta/gray-rects';
import Title from '../cta/title';
import Ratings from '../cta/ratings';
import CTAForm from '../../../forms/cta';

const containerClassName = cntl`
  bg-gray-100
  py-16
  sm:py-24
`;

const wrapperClassName = cntl`
  relative
  sm:py-16
`;

const contentClassName = cntl`
  mx-auto
  max-w-md
  px-4
  sm:max-w-3xl
  sm:px-6
  lg:max-w-7xl
  lg:px-8
`;

const contentWrapperClassName = cntl`
  relative
  rounded-2xl
  px-6
  py-10
  bg-gray-800
  overflow-hidden
  shadow-xl
  sm:px-12
  sm:py-20
`;

const formWrapperClassName = cntl`
  mt-8
  sm:max-w-lg
  sm:mx-auto
  sm:text-center
`;

const CTASection: React.FC<
  PageSectionProps<IndexPageQuery, IndexPageRuntimeQuery>
> = ({ runtimeData }) => {
  return (
    <div className={containerClassName}>
      <div className={wrapperClassName}>
        <GrayRects />
        <div className={contentClassName}>
          <div className={contentWrapperClassName}>
            <div className="relative">
              <Title>Enter your address to get started</Title>
              <div className={formWrapperClassName}>
                {typeof runtimeData !== 'undefined' && (
                  <>
                    <CTAForm
                      variation="transparent"
                      runtimeData={runtimeData}
                    />
                    <Ratings />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CTASection;
