import React from 'react';
import cntl from 'cntl';
import {
  IndexPageQuery,
  IndexPageRuntimeQuery,
  PageSectionProps,
} from '../../../@types';
import Header from '../../header/header';
import Hero from '../hero/hero';
import GrayRects from '../hero/gray-rects';

const mainWrapperClassName = cntl`
  relative
  bg-gray-100
  overflow-hidden
`;

const contentWrapperClassName = cntl`
  relative
  pt-6
  pb-16
  sm:pb-24
  lg:pb-32
`;

const MainSection: React.FC<
  PageSectionProps<IndexPageQuery, IndexPageRuntimeQuery>
> = ({ data, runtimeData }) => {
  return (
    <div className={mainWrapperClassName}>
      <GrayRects />
      <div className={contentWrapperClassName}>
        <Header
          phoneNumber={data.businessPhone.description.description}
          isCentered
          hasMainCTA
          navigation={[]}
        />
        <Hero data={data} runtimeData={runtimeData} />
      </div>
    </div>
  );
};

export default MainSection;
