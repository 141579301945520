import React from 'react';
import cntl from 'cntl';
import { IndexPageQuery, PageSectionProps } from '../../../@types';
import Card from '../stats/card';

const containerClassName = cntl`
  relative
  bg-gray-100
  pb-12
  sm:pb-16
  px-4
  sm:px-6
  lg:px-8
`;

const cardWidthFixerClassName = cntl`
  max-w-4xl
  mx-auto
`;

const cardWrapperClassName = cntl`
  rounded-lg
  bg-white
  shadow-lg
  sm:grid
  sm:grid-cols-3
  max-w-7xl
  mx-auto
`;

const statCards = [
  {
    name: 'Happy Customers',
    value: '700+',
  },
  {
    name: 'Moneyback Guaranteed',
    value: '100%',
  },
  {
    name: 'Years In Business',
    value: '8',
  },
];

const StatsSection: React.FC<PageSectionProps<IndexPageQuery>> = () => {
  return (
    <div className={containerClassName}>
      <div className={cardWidthFixerClassName}>
        <dl className={cardWrapperClassName}>
          {statCards.map(({ name, value }) => (
            <Card name={name} value={value} key={name} />
          ))}
        </dl>
      </div>
    </div>
  );
};

export default StatsSection;
