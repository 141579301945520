import React from 'react';
import cntl from 'cntl';
import Stars from '../../stars';
import facebookIconSrc from '../../../assets/social/facebook.svg';
import googleIconSrc from '../../../assets/social/google.svg';
import yelpIconSrc from '../../../assets/social/yelp.svg';

const ratingsWrapperClassName = cntl`
  inline-flex
  mx-auto
`;

const socialIconClassName = cntl`
  h-5
  w-5
  mx-2
`;

const textWrapperClassName = cntl`
  text-sm
  text-gray-500
  py-3
`;

interface SocialIcon {
  src: string;
  title: string;
}

const socialIcons: SocialIcon[] = [
  {
    src: googleIconSrc,
    title: 'Google',
  },
  {
    src: facebookIconSrc,
    title: 'Facebook',
  },
  {
    src: yelpIconSrc,
    title: 'Yelp',
  },
];

const Ratings: React.FC = () => {
  return (
    <div className="mt-6">
      <div className={textWrapperClassName}>
        <div>
          <span className="font-medium text-gray-900">
            Perfect 5 star rating
          </span>{' '}
          on{' '}
          <span className="font-medium text-primary-600">
            Google, Facebook, and Yelp.
          </span>
        </div>
      </div>
      <div className={ratingsWrapperClassName}>
        <div className="pr-2">
          <Stars />
        </div>
        <div className="flex">
          {socialIcons.map(({ src, title }, i) => (
            <img
              key={i}
              className={socialIconClassName}
              src={src}
              alt={title}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Ratings;
