import React from 'react';
import cntl from 'cntl';
import { DeviceMobileIcon } from '@heroicons/react/outline';
import { StaticImage } from 'gatsby-plugin-image';
import TextContent from './text-content';
import Testimonial from './testimonial';
import { Testimonial as ContentfulTestimonial } from '../../../@types';

interface BookInstantProps {
  testimonial: ContentfulTestimonial;
}

const containerClassName = cntl`
  px-4
  max-w-xl
  mx-auto
  sm:px-6
  lg:py-16
  lg:max-w-none
  lg:mx-0
  lg:px-0
`;

const imageContainerClassName = cntl`
  mt-12
  sm:mt-16
  lg:mt-0
`;

const imageWrapperClassName = cntl`
  pl-4
  -mr-48
  sm:pl-6
  md:-mr-16
  lg:px-0
  lg:m-0
  lg:relative
  lg:h-full
`;

const imageClassName = cntl`
  w-full
  rounded-xl
  lg:absolute
  lg:left-0
  lg:h-full
  lg:w-auto
  lg:max-w-none
`;

const BookInstant: React.FC<BookInstantProps> = ({ testimonial }) => {
  return (
    <>
      <div className={containerClassName}>
        <TextContent
          title="Book instantly, from your phone"
          icon={DeviceMobileIcon}
          ctaPath="/booking"
          ctaText="Book your detail"
        >
          We want to free our customers from cumbersome back and forth phone
          calls, emails, and text messages, so we made booking a detail as easy
          as shopping online.
        </TextContent>
        <Testimonial testimonial={testimonial} />
      </div>
      <div className={imageContainerClassName}>
        <div className={imageWrapperClassName}>
          <StaticImage
            placeholder="blurred"
            className={imageClassName}
            src="../../../assets/booking-flow.png"
            alt="Booking flow"
            objectFit="contain"
          />
        </div>
      </div>
    </>
  );
};

export default BookInstant;
