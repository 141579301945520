import React from 'react';
import { navigate } from 'gatsby';
import { Form, Formik, FormikHelpers } from 'formik';
import cntl from 'cntl';
import { schema } from './schemas/cta';
import {
  IndexPageRuntimeQuery,
  InputVariationEnum,
  RuntimeQueryHookState,
} from '../@types';
import { CTAFormState } from '../@types';
import MapInput from '../components/landing/map-input';
import MapAutocompleteField from './fields/map-autocomplete';
import isPlaceSupported from '../runtime/is-place-supported';
import { setAddress } from '../runtime/address-storage';

const formClassName = cntl`
  mt-3
  sm:flex
  items-start
`;

const submitButtonClassName = cntl`
  mt-3
  w-full
  px-6
  py-3
  border
  border-transparent
  text-base
  font-medium
  rounded
  text-white
  bg-primary-600
  shadow-sm
  hover:bg-primary-800
  focus:outline-none
  focus:ring-1
  focus:ring-offset-2
  focus:ring-primary-500
  sm:mt-0
  sm:ml-3
  sm:flex-shrink-0
  sm:inline-flex
  sm:items-center
  sm:w-auto
`;

interface CTAFormProps {
  runtimeData: RuntimeQueryHookState<IndexPageRuntimeQuery>;
  variation?: InputVariationEnum;
}

const CTAForm: React.FC<CTAFormProps> = ({ runtimeData, variation }) => {
  const initialValues: CTAFormState = {
    address: null,
  };

  const onSubmit = (
    values: CTAFormState,
    helpers: FormikHelpers<CTAFormState>
  ) => {
    const { data } = runtimeData;
    const { address } = values;

    if (data === null || address === null) {
      return;
    }

    if (!isPlaceSupported(data.serviceAreas, address)) {
      helpers.setErrors({
        address: 'Service is not available in provided address',
      });
      return;
    }

    setAddress(address);
    navigate('/booking');
  };

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <Form
        onKeyDown={(event: React.KeyboardEvent) => {
          if (event.key === 'Enter') {
            event.preventDefault();
          }
        }}
        className={formClassName}
      >
        <MapAutocompleteField
          disabled={runtimeData.loading}
          name="address"
          variation={variation}
          element={MapInput}
          placeholder="Enter your address"
        />
        <button type="submit" className={submitButtonClassName}>
          Book Your Detail
        </button>
      </Form>
    </Formik>
  );
};

export default CTAForm;
