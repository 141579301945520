import React from 'react';
import cntl from 'cntl';
import {
  ExclamationCircleIcon,
  LocationMarkerIcon,
} from '@heroicons/react/solid';
import { classNames } from '../../utils';
import { CustomInputProps } from '../../@types';

const inputBaseClassName = cntl`
  block
  w-full
  pl-10
  py-3
  text-base
  rounded
  shadow-sm
  sm:flex-1
`;

const whiteClassName = cntl`
  placeholder-gray-500
  focus:ring-primary-500
  focus:border-primary-500
  border-gray-300
  bg-white
`;

const transparentInputClassName = cntl`
  bg-transparent
  border-white
  focus:ring-primary-500
  focus:border-primary-500
  placeholder-white
  text-white
`;

const errorClassName = cntl`
  border-red-300
  placeholder-red-300
  focus:ring-red-500
  focus:border-red-500
`;

const blockClassName = cntl`
  block
  w-full
`;

const errorMessageClassName = cntl`
  mt-2
  text-sm
  text-red-600
  text-left
`;

const iconWrapperClassName = cntl`
  absolute
  inset-y-0
  right-0
  pr-3
  flex
  items-center
  pointer-events-none
`;

const locationIconWrapperClassName = cntl`
  absolute
  inset-y-0
  left-0
  pl-3
  flex
  items-center
  pointer-events-none
`;

const locationIconClassName = cntl`
  h-5
  w-5
  text-gray-400
`;

const iconClassName = cntl`
  h-5
  w-5
  text-red-500
`;

const MapInput = React.forwardRef<
  HTMLInputElement,
  React.HTMLProps<HTMLInputElement> & CustomInputProps
>((props, ref) => {
  const error = props.error;

  return (
    <div className={blockClassName}>
      <div className={`relative ${blockClassName}`}>
        <div className={locationIconWrapperClassName}>
          <LocationMarkerIcon
            className={locationIconClassName}
            aria-hidden="true"
          />
        </div>
        <input
          {...props}
          ref={ref}
          type="text"
          className={classNames(
            inputBaseClassName,
            error ? errorClassName : '',
            props.variation === 'white'
              ? whiteClassName
              : transparentInputClassName
          )}
        />
        {error && (
          <div className={iconWrapperClassName}>
            <ExclamationCircleIcon
              className={iconClassName}
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {error && <p className={errorMessageClassName}>{error}</p>}
    </div>
  );
});

export default MapInput;
