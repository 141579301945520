import React from 'react';
import cntl from 'cntl';

const titleClassName = cntl`
  text-3xl
  font-extrabold
  text-white
  tracking-tight
  sm:text-4xl
`;

const Title: React.FC = ({ children }) => {
  return (
    <div className="sm:text-center">
      <h2 className={titleClassName}>{children}</h2>
    </div>
  );
};

export default Title;
