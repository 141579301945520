import React from 'react';
import cntl from 'cntl';
import {
  PageSectionProps,
  IndexPageQuery,
  IndexPageRuntimeQuery,
} from '../../../@types';
import Title from './title';
import Description from './description';
import Ratings from './ratings';
import CTAForm from '../../../forms/cta';

const mainClassName = cntl`
  pt-16
  mx-auto
  max-w-7xl
  px-4
  sm:px-6
  relative
`;

const textContainerClassName = cntl`
  text-center
  max-w-3xl
  mx-auto
`;

const formWrapperClassName = cntl`
  mt-8
  sm:max-w-lg
  sm:mx-auto
  sm:text-center
`;

const Hero: React.FC<
  PageSectionProps<IndexPageQuery, IndexPageRuntimeQuery>
> = ({ data, runtimeData }) => {
  const siteTitle = data.siteTitle.description.description.split('\n');
  const primary = siteTitle[0];
  const secondary = siteTitle[1];

  return (
    <main className={mainClassName}>
      <div className={textContainerClassName}>
        <Title primary={primary} secondary={secondary} />
        <Description>
          {data.siteDescription.description.description}
        </Description>
        <div className={formWrapperClassName}>
          {typeof runtimeData !== 'undefined' && (
            <>
              <CTAForm variation="white" runtimeData={runtimeData} />
              <Ratings />
            </>
          )}
        </div>
      </div>
    </main>
  );
};

export default Hero;
