import React, { useEffect, useState } from 'react';
import cntl from 'cntl';
import { IndexPageQuery, PageSectionProps } from '../../../@types';
import BookInstant from '../features/book-instant';
import ServiceAreas from '../features/service-areas';

const containerClassName = cntl`
  relative
  bg-gray-100
  pt-16
  pb-32
  overflow-hidden
`;

const featureContainerClassName = cntl`
  lg:mx-auto
  lg:max-w-7xl
  lg:px-8
  lg:grid
  lg:grid-cols-2
  lg:grid-flow-col-dense
  lg:gap-24
`;

const FeaturesSection: React.FC<PageSectionProps<IndexPageQuery>> = ({
  data,
}) => {
  const [testimonialIndex, setTestimonialIndex] = useState(0);
  const testimonials = data.testimonials.nodes;
  const testimonial = testimonials[testimonialIndex];

  useEffect(() => {
    setTestimonialIndex(Math.floor(Math.random() * testimonials.length));
  }, []);

  return (
    <div className={containerClassName}>
      <div className="relative">
        <div className={featureContainerClassName}>
          <BookInstant testimonial={testimonial} />
        </div>
      </div>
      <div className="mt-24">
        <div className={featureContainerClassName}>
          <ServiceAreas />
        </div>
      </div>
    </div>
  );
};

export default FeaturesSection;
