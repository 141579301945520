import React from 'react';
import cntl from 'cntl';
import { StaticImage } from 'gatsby-plugin-image';

const sectionClassName = cntl`
  relative
  py-16
  sm:py-24
  bg-gray-100
`;

const containerClassName = cntl`
  mx-auto
  max-w-md
  px-4
  sm:max-w-3xl
  sm:px-6
  lg:max-w-7xl
  lg:px-8
`;

const wrapperClassName = cntl`
  relative
  rounded-xl
  bg-gray-800
  overflow-hidden
  shadow-xl
`;

const imageWrapperClassName = cntl`
  h-72
  sm:h-4/6
  bg-primary-600
  lg:absolute
  lg:left-0
  lg:h-full
  lg:w-1/2
`;

const imageClassName = cntl`
  w-full
  h-full
  object-cover
`;

const textContainerClassName = cntl`
  relative
  max-w-7xl
  mx-auto
  px-4
  py-12
  sm:px-6
  lg:px-8
  lg:py-16
`;

const textWrapperClassName = cntl`
  lg:ml-auto
  lg:w-1/2
  lg:pl-10
`;

const titleClassName = cntl`
  mt-2
  text-white
  text-3xl
  font-extrabold
  tracking-tight
  sm:text-4xl
`;

const descriptionClassName = cntl`
  mt-3
  text-lg
  text-gray-300
`;

const ownerSectionContainerClassName = cntl`
  mt-8
  flex
  items-center
`;

const avatarClassName = cntl`
  w-14
  h-14
  rounded-full
`;

const ownerTextClassName = cntl`
  mt-1
  text-xs
  text-white
`;

const FounderMessage: React.FC = () => {
  return (
    <section className={sectionClassName}>
      <div className={containerClassName}>
        <div className={wrapperClassName}>
          <div className={imageWrapperClassName}>
            <StaticImage
              className={imageClassName}
              src="../../../assets/porsche.png"
              alt=""
            />
          </div>
          <div className={textContainerClassName}>
            <div className={textWrapperClassName}>
              <p className={titleClassName}>
                We’re proper detailing professionals.
              </p>
              <p className={descriptionClassName}>
                Dave's Auto Details started with a passion for cars and an
                obsession with bringing out the very best in them. In 2011 I
                decided building a business from that passion was what I wanted
                to do with my life.
              </p>
              <p className={descriptionClassName}>
                Thousands of details later and I’m more excited than ever for
                what is next for Dave's Auto Details. I put my name behind every
                detail and guarantee you’ll love it or you won’t pay a penny.
              </p>
              <div className={ownerSectionContainerClassName}>
                <StaticImage
                  className={avatarClassName}
                  quality={100}
                  placeholder="blurred"
                  src="../../../assets/avatar.png"
                  alt=""
                />
                <div className="ml-5">
                  <StaticImage
                    className="w-40"
                    quality={100}
                    placeholder="blurred"
                    src="../../../assets/signature.png"
                    alt=""
                  />
                  <div className={ownerTextClassName}>
                    Owner - Dave's Auto Details
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FounderMessage;
