import React from 'react';
import cntl from 'cntl';
import { Link } from 'gatsby';

const iconWrapperClassName = cntl`
  h-12
  w-12
  rounded-md
  flex
  items-center
  justify-center
  bg-primary-600
`;

const iconClassName = cntl`
  h-6
  w-6
  text-white
`;

const titleClassName = cntl`
  text-3xl
  font-extrabold
  tracking-tight
  text-gray-900
`;

const descriptionClassName = cntl`
  mt-4
  text-lg
  text-gray-500
`;

const bookButtonClassName = cntl`
  inline-flex
  px-6
  py-3
  border
  border-transparent
  text-base
  font-medium
  rounded-md
  shadow-sm
  text-white
  bg-primary-600
  hover:bg-primary-700
`;

interface TextContentProps {
  title: string;
  ctaText: string;
  ctaPath: string;
  icon: React.ElementType;
}

const TextContent: React.FC<TextContentProps> = ({
  title,
  ctaText,
  ctaPath,
  icon: Icon,
  children,
}) => {
  return (
    <div>
      <div>
        <span className={iconWrapperClassName}>
          <Icon className={iconClassName} aria-hidden="true" />
        </span>
      </div>
      <div className="mt-6">
        <h2 className={titleClassName}>{title}</h2>
        <p className={descriptionClassName}>{children}</p>
        <div className="mt-6">
          <Link to={ctaPath} className={bookButtonClassName}>
            {ctaText}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TextContent;
